<script>
	import {createEventDispatcher} from 'svelte';
	import {state, useAlchemy, claimRonin} from '../../stores/manager';
	import {data, Contract} from '../../stores/contracts';
	import {fade} from 'svelte/transition';
	import Haiku from '../../../public/assets/images/bghaiku.svelte';
	import TokenSelector from '../TokenSelector.svelte';
	import HazeLayer from '../shared/HazeLayer.svelte';
	import {navigateTo} from 'svelte-router-spa';
	import { BackgroundCement, KitsuneUnclaimed } from '../../../public/assets/urls';

	let w;
	let preCeremony = [];
	let roninCollection = [];
	let selected = -1;

	// export let enabled;
	export let highlighted;

	const dispatch = createEventDispatcher();

	const switchToProfile = (a) => {

		// dispatch("viewProfile", "profile ready for viewing")
		dispatch("viewProfile", a.detail)
		highlighted = true;
	}

	// var keys = [];

	let tokens = [];
	$:{
		tokens = [];

		$data.myRonins.value.forEach(roninId => {
			let token = {
				tokenId: roninId,
				type: "ronin"
			}
			if (token.tokenId < 10000) {
				preCeremony.push(roninId);
				token.sortPos = roninId;
				token.enabled = true;
			} else {
				token.sortPos = roninId - 10000;
				token.enabled = false;
			}
			tokens.push(token)
		});
		$data.myKitsunes.value.forEach(kitsuneId =>{
			let token = {
				tokenId: kitsuneId,
				type: "kitsune",
				sortPos: 20000 + parseInt(kitsuneId),
			}
			tokens.push(token);
		});

		tokens.sort((a,b) => {
			return a.sortPos < b.sortPos? -1:1;
		})

	}



	// $:{
	// 	preCeremony = [];
	// 	roninCollection = [];
	//
	// 	$data.myRonins.value.forEach(roninId => {
	//
	// 		let token = {
	// 			tokenId: roninId
	// 		}
	// 		if (token.tokenId < 10000) {
	// 			preCeremony.push(roninId);
	// 			token.sortPos = roninId;
	// 			token.enabled = true;
	// 		} else {
	// 			token.sortPos = roninId - 10000;
	// 			token.enabled = false;
	// 		}
	// 		roninCollection.push(token)
	// 	})
	// 	roninCollection.sort((a,b) => {
	// 		return a.sortPos < b.sortPos? -1:1;
	// 	})
	// }

	// TODO: For each Ronin, their Kitsune should be loaded after the Ronin into roninCollection.
</script>

<style type="text/css">
  .scrollbar::-webkit-scrollbar {
    width: 12px;
    height: 20px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #282828;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #444444;
    border-radius: 100vh;
    border: 3px solid #444444;
    margin: 2px;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0a0b9;
  }
</style>


<svelte:window bind:innerWidth={w}/>

<div in:fade={{delay: 100, duration: 800}} out:fade={{delay: 100, duration: 800}}>
	<div class="fog_container hero">
		<!-- <HazeLayer/> -->
		<div in:fade={{duration: 1200}} out:fade={{delay: 100, duration: 800}}>
			<h1 class="profile_title {w < 600 ? "mt-32":"mt-32"}">YOUR NFTs</h1>
			<p class="{w > 500 ? 'section_content_center text-center font-normal' : "section_content_center_sm text-center"}">
				View your full collection of <span class="font-bold gold-color">Oni Ronin in high-level detail</span>
			</p>

			<div class="flex items-center justify-center mt-4">
				<img class="h-10 mr-3" src="{KitsuneUnclaimed}"/>
				<span class="text-sm">Kitsune Available for Claim</span>
			</div>

			<div class="mt-7 pl-8 pr-6 py-10 relative">
				<img src="{BackgroundCement}" class="absolute w-full h-full top-0 left-0 rounded-lg opacity-30"/>
				<div class="profile_scrollview scrollbar relative z-10 opacity-100">

					{#if tokens.length > 0}
						<TokenSelector
									tokensSorted={tokens}

									bind:selectedId={selected}
									on:readyProfile={switchToProfile}
						/>
					{:else if  tokens.length === 0 && $useAlchemy === false}
						<div class="alert-container mx-auto mt-40 flex flex-col justify-center" in:fade out:fade>
							<div>
								<p class="text-sm">
									You have no Oni Ronin to view.
								</p>
								<button class="base-btn-asc" on:click={() => navigateTo('mint')}>MINT</button>
								<a class="base-btn-asc-alt" href="https://opensea.io/collection/oni-ronin" target="_blank">VIEW ON OPENSEA</a>
							</div>
						</div>
					{:else if $useAlchemy}
						<div class="alert-container mx-auto mt-40 flex flex-col justify-center" in:fade>
							<p class="text-sm inline-block align-middle">
								Please Connect Your Wallet to Continue.
							</p>
						</div>
					{/if}

				</div>
			</div>
		</div>
	</div>
</div>
