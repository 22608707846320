<script>
	import {onMount} from 'svelte'
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import {data as data_alchemy} from '../../stores/web3/alchemy/contracts'
	import {navigateTo} from 'svelte-router-spa';
	import {FallenRoninLarge, FallenRoninMobile, ToriiGates} from '../../../public/assets/urls'
	import Haiku from '../../../public/assets/images/bghaiku.svelte'

	let w;
	let Ronin;

	onMount(async () => {
		if (w > 500) {
			Ronin = FallenRoninLarge
		} else if (w < 500) {
			Ronin = FallenRoninMobile
		}
	});

	$: unclaimed = Number($data.roninUnclaimed.value);

	const initiateClaim = () => {
		claimRonin(unclaimed)
	}

	const gotoAscend = () => {
		navigateTo('ascend');
	}

</script>

<svelte:window bind:innerWidth={w}/>

<div id="main_section" class="container mx-auto max-w-screen-2xl">
	<div class="relative">
		<div class="mt-6 mr-32 lg:mr-48 absolute right-0 w-72">
			<Haiku/>
		</div>
		<div class="-mr-10 lg:mr-0 mt-1 absolute right-0">
			<img src={ToriiGates} alt={''} class="max-w-xs lg:max-w-md opacity-60" />
		</div>

		<div class="px-0 lg:px-12 mt-16 relative">
			<div class="flex flex-col-reverse lg:flex-row">

				<div class="mt-6 lg:-mt-4 lg:-mr-10 flex justify-center">
					<img src={Ronin} class=" lg:max-w-6xl scale-110"/>
				</div>

				<div class="z-10 flex flex-col mx-8 mt-32 lg:ml-16">
					<div class=" mx-auto text-left text-white lg:ml-0">
						<h1 class="main-font text-sm lg:text-xl font-bold mb-1">TRIAL OF</h1>
						<h2 class="main-font text-4xl lg:text-6xl font-bold mb-8">ASCENSION</h2>
						<p class="section_content">There is only one escape for the Oni Ronin: The Trial of Ascension</p>
						<p class="section_content">A Ronin with exceptional skill may challenge its Oni in battle. This is not without great risk.
							Those who attempt the Trial of Ascension and fail will fall deeper into eternal shame and torment, becoming a <span class="font-bold gold-color">Fallen Ronin</span>
						</p>
						<p class="section_content">
							But for those who succeed in defeating their Oni - they can reclaim their honor and shed their curse by becoming the <span class="font-bold gold-color">Golden Ronin</span>
						</p>


						<button class="gold-background px-14 py-3 mt-2 lg:mt-6 secondary-font text-black rounded-full text-xs sm:text-base float-right sm:leading-none" on:click={gotoAscend}>
							ASCEND
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
