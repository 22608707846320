<script>
	import {createEventDispatcher} from 'svelte';
	import {fade} from 'svelte/transition';
	import {profile, profiles} from '../../stores/metadata';
	import {mountedForAscension, identifyRonin, isGold, isGoldLoaded} from '../../stores/transactions';
	import {toggleZoom, setEnhancement} from '../../stores/ui';
	import {navigateTo} from 'svelte-router-spa';
	import {claimable, data} from "../../stores/manager";

	import {KitsuneUnclaimed} from '../../../public/assets/urls';
	import {get} from "svelte/store";

	const opensea = process.env.OPENSEA_SHARE;

	const dispatch = createEventDispatcher();

	const newWindow = (url) => { window.open(url) };

	const clickForOpenSea = () => { newWindow( `${opensea}`+ profile.trueID ) };

	const handleZoom = () => { toggleZoom() }

	let w;
	let zoom = false;
	let urlLoaded = false;

	let golden = false;
	let fallen = false;

	let owner;
	let myRonins = $data.myRonins.value;

	let roninId = 0;

	let token = 0;
	let inWallet = myRonins.includes(Number(roninId))

	$: {
		if (myRonins !== []) {
			if (inWallet === true) {
				owner = true;
			} else {
				owner = false;
			}
		}
	}


	
	$: if (profile.trueID > 10000) {
		token = profile.trueID - 10000 
	} else {
		token = profile.trueID - 0
	}



	$:{
		if(roninId){
			if(roninId > 10000) {
				token = roninId - 10000
			}else{
				token = roninId
			}
		}

	}


	let canClaimKitsune = false;
	$:{
		canClaimKitsune = $data.unclaimedKitsunes.value.length > 0 && $data.unclaimedKitsunes.value.includes(parseInt(profile.trueID));
	}
	let isMyKitsune = false;
	$:{
		isMyKitsune = $data.myRonins.value.includes(parseInt(profile.trueID)) || $data.myRonins.value.includes(BigInt(profile.trueID));
	}

	function claimKitsuneClick(){
		navigateTo("kitsune/"+profile.trueID)
	}


	const returnToCollection = () => {
		dispatch("hideProfile", "return back to main menu");
	}

	const selectForAscension = () => {
		mountedForAscension()
		navigateTo('/ascend')
		identifyRonin(profile.name)
	}

	const updateWindowURL = () => {
		window.history.replaceState
		(
			{},
			`Oni Ronin - Ronin ${profile.trueID}`,
			`/ronin/${profile.trueID}`
		)
		urlLoaded = true;
	}

	$: urlLoaded === false ? updateWindowURL() : null

	$: $setEnhancement === true ? zoom = true : zoom = false;

	$: if (profile.trueID > 10000) {
			$isGold ? golden = true : fallen = true;
	}


	let isActuallyGold = false;
	$: {
		if($profiles && $profiles.traits ){
			isActuallyGold = false;
			for(let i = 0; i < $profiles.traits.length; i++){
				let trait = $profiles.traits[i];
				if(trait.trait_type === "Soul" && trait.value === "金色の浪士"){
					isActuallyGold = true;
					break;
				}
			}
		}
	}




	let kitsuneCheckReady = false;
	let kitsuneAvailable = false;
	let kitsuneCheckDone = false;

	async function checkClaimable(){
		kitsuneCheckDone = true;
		kitsuneAvailable = await claimable(roninId);
	}

	$:{
		kitsuneCheckReady = $data.unclaimedKitsunes.loaded;

		// kitsuneAvailable = $data.unclaimedKitsunes.value[roninId];
		if(kitsuneCheckReady && !kitsuneCheckDone){
			checkClaimable();
		}
	}


</script>

<svelte:window bind:innerWidth={w}/>

<div class="fog_container profile_container" in:fade>
	<div class="mt-48 {w > 600 ? 'profile_columns':'profile_rows'}">

		<div class="{w > 600 ? 'left_col': 'left_col_mobile mt-32'}">
			{#if w < 600}
				{#if isActuallyGold}
					<h1 class="ronin_title_mobile -mb-4">GOLDEN</h1>
					<h1 class="ronin_title_mobile -mt-5">RONIN</h1>
				{:else if fallen}
					<h1 class="ronin_title_mobile -mb-4">FALLEN</h1>
					<h1 class="ronin_title_mobile -mt-5">RONIN</h1>
				{:else}
					<h1 class="ronin_title_mobile">RONIN</h1>
				{/if}
				<h1 class="section_highlight section_tagline_center mb-5 animate-pulse duration-1000">
					#{token}
				</h1>
		{/if}
			<div>
				<img id="currentRonin" src={profile.url} alt={profile.name} class="{w > 600 ? 'ronin_img':'ronin_img_responsive'}{zoom === true ? "ronin_img_hidden":""}" on:click={toggleZoom} />

			{#if canClaimKitsune}
				<img src="{KitsuneUnclaimed}" class="h-9 w-9 relative bottom-10 right-1" style="float: right"  />
			{/if}

				<div style="background: none; margin-top:60px; display: flex; flex-direction: row; justify-content: center; align-items: center; font-size: 14px;">
					{#if kitsuneCheckReady}
						{#if kitsuneAvailable}
							<!--{#if canClaimKitsune}-->

							<span style="background: none;">
											<img src="{KitsuneUnclaimed}" class="h-9 w-9 bottom-1"  />
										</span>
							<span style="padding-left: 10px;">
											Kitsune Available for Claim
										</span>
						{:else}

							Kitsune NOT Available for Claim with this Ronin
						{/if}
					{/if}
				</div>
			</div>

			<!-- Modal -->
			<div id="myModal" class="{zoom === true ? "modal_open": "modal"}">
			<img class="modal-content" id="img01" src={profile.url} alt={profile.name} on:click={handleZoom}>
			<!-- Modal Ends -->
			
			</div>
		</div>
		<div class="right_col text-left">
			{#if w > 600}
				{#if isActuallyGold}
					<h1 class="ronin_title -mb-9">GOLDEN</h1>
					<h1 class="ronin_title -mt-5">RONIN</h1>
				{:else if fallen === true}
					<h1 class="ronin_title -mb-9">FALLEN</h1>
					<h1 class="ronin_title -mt-5">RONIN</h1>
				{:else}
					<h1 class="ronin_title">RONIN</h1>
				{/if}
					<h1 class="section_highlight section_tagline animate-pulse duration-1000">
						#{token}
					</h1>
			{/if}
			{#if w > 600}
				<p class="fine_print text-sm text-white-600 animate-pulse duration-1000">Available Actions</p>
					{#if profile.trueID < 9999}
						<div class="grid grid-flow-col gap-4 my-6">

							<button class="gold-background w-48 py-2 secondary-font text-black rounded-full text-xs hover:animate-pulse duration-1000" on:click={selectForAscension}>ASCEND</button>

							{#if canClaimKitsune  && isMyKitsune}
								<button on:click={claimKitsuneClick} class="gold-background w-48 py-2secondary-font text-black rounded-full text-xs hover:animate-pulse duration-1000">CLAIM KITSUNE</button>
							{/if}

							<button on:click={toggleZoom} class="gold-border w-48 py-2 secondary-font gold-color rounded-full text-xs hover:animate-pulse duration-1000">ENHANCE</button>
							<button on:click={clickForOpenSea} class="gold-border w-48 py-2 secondary-font gold-color rounded-full text-xs hover:animate-pulse duration-1000">VIEW ON OPENSEA</button>
						</div>
				{:else}
					<div class="-ml-3">
						{#if canClaimKitsune  && isMyKitsune}
							<button on:click={claimKitsuneClick} class="base-btn-asc hover:animate-pulse duration-1000">CLAIM KITSUNE</button>
						{/if}
						<button on:click={clickForOpenSea} class="base-btn-asc-alt hover:animate-pulse duration-1000">VIEW ON OPENSEA</button>
						<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
					</div>
				{/if}
			{:else}
				<div class="text-center profile_controls_mobile mb-5">
					<p class="fine_print text-center text-sm text-white-600 animate-pulse duration-1000 -mt-5">Available Actions</p>
					{#if profile.trueID < 9999}
						{#if owner}
							<button class="base-btn-asc-mobile" on:click={selectForAscension}>ASCEND</button>
						{/if}
					{/if}
					{#if canClaimKitsune && isMyKitsune}
						<button on:click={claimKitsuneClick} class="base-btn-asc-mobile">CLAIM KITSUNE</button>
					{/if}
					<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
					<button on:click={clickForOpenSea} class="base-btn-asc-alt-mobile">VIEW ON OPENSEA</button>
				</div>
			{/if}
			{#if profile.traits}
				<div class="{w > 600 ? "attr":"mobile_attr"} -mt-10">
					{#each profile.traits as attributes}
						<p class="mb-1 {w > 600 ? '':''}">
							<span class="attr_type mr-1">{attributes.trait_type}:</span>
								<span class="attr_value">{attributes.value}</span>
						</p>
					{/each}
				</div>
			{/if}

			<!--{#if kitsuneCheckReady}-->
			<!--	{#if kitsuneAvailable}-->
			<!--		<span style="background: none;">-->
			<!--			<img src="{KitsuneUnclaimed}" class="h-9 w-9 bottom-1"  />-->
			<!--		</span>-->
			<!--		<span style="padding-left: 10px;">-->
			<!--			Kitsune Available for Claim-->
			<!--		</span>-->
			<!--	{:else}-->

			<!--		Kitsune NOT Available for Claim with this Ronin-->
			<!--	{/if}-->
			<!--{/if}-->

		</div>
	</div>
</div>

<style>

	.ronin_img {
		height: 500px;
		width: 500px;
		margin: 0 auto;
		z-index: 5;
		outline: 2px solid gold;
		background: black;
	}

	.left_col {
		display: flex;
		flex-direction: column;
		align-self: center;
		margin-bottom: 1em;
		margin-right: 2em;
		height: 500px;
		width: 500px;
	}
	.left_col_mobile {
		display: flex;
		flex-direction: column;
		align-self: center;
		margin-bottom: 1em;
		height: 500px;
		width: auto;
	}
	.right_col {
		align-items: center;
		justify-content: center;
	}

</style>