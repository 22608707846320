<script>
	import {onMount} from 'svelte';
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import {data as data_alchemy} from '../../stores/web3/alchemy/contracts'
	import Haiku from '../../../public/assets/images/bghaiku.svelte';
	import {GoldenRoninLarge, GoldenRoninMobile} from '../../../public/assets/urls'

	let Ronin;

	onMount(async () => {
		if (w > 500) {
			Ronin = GoldenRoninLarge
		} else if (w < 500) {
			Ronin = GoldenRoninMobile
		}
	});

	var keys = []
	let w;

	$: unclaimed = Number($data.roninUnclaimed.value)

	const initiateClaim = () => {
		claimRonin(unclaimed)
	}

	$: {
		keys = Object.keys($data_alchemy)
	}
</script>

<svelte:window bind:innerWidth={w}/>

<div id="main_section" class="container mx-auto max-w-screen-2xl section_2_container">
	<div class="w-full relative">
		<div class="mt-8 right-0 xl:left-0 xl:mt-0 -mr-12 sm:mr-12 xl:ml-24 absolute w-72">
			<Haiku/>
		</div>

		<div class="px-0 xl:px-12 mt-8">
			<div class="grid grid-cols-1 xl:grid-cols-2">

				<div class="z-10 flex flex-col mt-20 mx-8 xl:w-1/2">
					<div class=" mx-auto text-left text-white xl:ml-16">
						<h1 class="main-font text-3xl xl:text-6xl font-bold mb-3">TOKENOMICS</h1>

						<div class="xl:mr-12">
							<p class="secondary-font mb-6">
								Each Oni Ronin takes on twisted characteristics that reflect the corrupted soul.
							</p>

							<p class="secondary-font mb-6">
								These traits are determined at random and revealed at the moment of minting.  Some traits are common, others are legendarily rare.
							</p>

							<p class="secondary-font mb-6">
								<span class="font-bold gold-color">Of the 8888 Oni Ronin, only 88 will ever defeat their Oni in the Trial of Ascension</span> and become Golden Ronin.
								Whether a Ronin possesses the strength to defeat its Oni is determined at minting, but can only be revealed by attempting the Trial of Ascension.
							</p>


							<div class="grid grid-cols-2">
								<div class="font-fold text-3xl xl:text-4xl main-font gold-color">
									8,888
								</div>
								<div class="font-fold text-3xl xl:text-4xl main-font gold-color">
									0.0888
								</div>
								<span class="font-bold text-xs">Individual Oni Ronin</span>
								<span class="font-bold text-xs">ETH each</span>

							</div>

						</div>
					</div>
				</div>

				<div class="mt-6 xl:-mt-4 -ml-12 xl:ml-4 flex justify-center xl:w-1/2">
					<img src={Ronin} class="max-w-full xl:max-w-4xl xl:scale-110"/>
				</div>
			</div>
		</div>
	</div>
</div>
