<script>
	import {onMount} from 'svelte';
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import {data as data_alchemy} from '../../stores/web3/alchemy/contracts'
	import HazeLayer from '../shared/HazeLayer.svelte';
	import Haiku from '../../../public/assets/images/bghaiku.svelte'
	import {JiseiLarge, JiseiMobile} from '../../../public/assets/urls'

	let Jisei;

	onMount(async () => {
		if (w > 500) {
			Jisei = JiseiLarge
		} else if (w < 500) {
			Jisei = JiseiMobile
		}
	});

	var keys = [];
	let w;

	$: unclaimed = Number($data.roninUnclaimed.value)

	const initiateClaim = () => {
		claimRonin(unclaimed)
	}

	$: {
		keys = Object.keys($data_alchemy)
	}

</script>
<svelte:window bind:innerWidth={w}/>

<div id="main_section" class="container mx-auto max-w-screen-2xl">
	<div class="fog_container">
		<div class="mt-2 lg:mt-32 ml-24 absolute w-72 right-0 xl:left-0">
			<Haiku/>
		</div>

		<div class="px-0 lg:px-12 mt-16">
			<div class="grid grid-cols-1 lg:grid-cols-2">
				<div class="z-10 flex flex-col justify-center mx-8 -mt-24">
					<div class=" mx-auto text-left text-white lg:ml-16">
						<h1 class="main-font text-sm lg:text-xl font-bold mb-1">JISEI</h1>
						<h2 class="main-font text-4xl lg:text-6xl font-bold -mb-1">DEATH</h2>
						<h2 class="main-font text-4xl lg:text-6xl font-bold mb-4">POEMS</h2>

						<p class="section_content">
							Death is the Samurai’s shadow.
						</p>
						<p class="section_content">
							All Samurai, even Ronin, honored a tradition of composing <span class="font-bold gold-color">Haiku death poems</span> called <span class="font-bold gold-color">Jisei</span>. Verses inked as close as possible to the inevitable moment of death.
						</p>
						<p class="section_content">
							Every Oni Ronin NFT comes with 1 of the 20 different Haiku death poems adorning their backdrops plus an authentic recorded audio clip of it being read.
						</p>


						<!-- <button class="mt-4 float-left border border-amber-200 px-8 py-4 width-24 rounded-full">
							Audio Clip Here...
						</button> -->
					</div>
				</div>

				<div class="mt-6 lg:-mt-4 lg:-ml-10 flex justify-center">
					<img src={Jisei}  class="max-w-full lg:max-w-4xl "/>
				</div>
			</div>
		</div>
	</div>
</div>
