<script>
	import {fade} from 'svelte/transition';
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import {data as data_alchemy} from '../../stores/web3/alchemy/contracts'
	import Haiku from '../../../public/assets/images/bghaiku.svelte'
	import {profile, fetchIPFSProfile} from './../../stores/metadata.js';
	import {onMount} from 'svelte';
	import HazeLayer from '../shared/HazeLayer.svelte'
	import Spinner from '../shared/Spinner.svelte'
	import SuccessLayer from '../shared/SuccessLayer.svelte'
	import FailureLayer from '../shared/FailureLayer.svelte'
	import {Torii, asc_bg, mint_ticket as ticket, success_block, FogBottom} from '../../../public/assets/urls'
	import {navigateTo} from 'svelte-router-spa';
	import { TicketBox, AscensionBlockPlain, AscensionBlockFire, AscensionBlockGlow } from '../../../public/assets/urls';
	import { RoninLarge } from '../../../public/assets/urls';

	import {
		ID,
		LOADING,
		ASCENDING,
		ASCENSION_PENDING,
		ASCENSION_SUCCESS,
		ASCENDED,
		SELECTED,
		SELECTING,
		launchAscension,
		preAscension,
		cancelAscension,
		isGold,
		isGoldLoaded,
	} from '../../stores/transactions';


	onMount(() => {
		scrollTo(0,0);
	})

	let _ascending;
	let _selected;
	let _selecting;
	let _loading;
	let _ascended;
	let _ascensionPending;
	let _ascensionSuccess;
	let _id;

	let ascendedURL;

	let w;

	let selectedToken = ID;
	let updated;
	let testing;

	let token;

	let updateImage = async () => {
			fetchIPFSProfile(ID)
			setTimeout(() => {
				ascendedURL = profile.url
			},3000)
			updated = true
	}

	const prepForAscension = () => {
		navigateTo('yournfts');
	}

	const cancel = () => {
		cancelAscension();
	}

	const beginAscension = () => {
		launchAscension();
		updated = false;
	}
	const forwardToProfile = async () => {
		setTimeout(() => {
			cancelAscension();
		}, 100)
		navigateTo('/yournfts');
	}

	$:
	testing = {
		$ASCENDING,
		$SELECTING,
		$SELECTED,
		$ASCENSION_PENDING,
		$ASCENSION_SUCCESS,
		$LOADING,
		$ASCENDED,
		selectedToken,
	}


	$: {
		_ascending = $ASCENDING;
		_selecting = $SELECTING;
		_selected = $SELECTED;
		_ascensionPending = $ASCENSION_PENDING;
		_ascensionSuccess = $ASCENSION_SUCCESS;
		_loading = $LOADING;
		_ascended = $ASCENDED;
	}


	// Set true to debug ascended views.
	const ascendedView = _ascensionSuccess === true && _ascended === true;

	// TODO: Remove after testing.
	// Set for testing gold or not ascension.
	// $isGold = false;
	// updated = true;
	// _ascensionSuccess = true;
	// $isGoldLoaded = false;

	$:ID > 10000 ? token = ID - 10000 : token = ID

	$: if (_ascensionSuccess === true) {
		updateImage()
	}

	// $: {
	// 	console.log(testing)
	// }
</script>

<svelte:window bind:innerWidth={w}/>

<div id="main_section" class="container mx-auto sm:max-w-screen-sm lg:max-w-screen-2xl text-xs lg:text-base px-8 sm:px-24 md:px-10 lg:px-0 mt-24 lg:mt-24">
	<div class="relative w-full">
		{#if !ascendedView}
			<div class="text-center w-full px-8 text-xs lg:text-base">
				Even the most disgraced Oni Ronin can seek redemption through the <span class="font-bold gold-color">Trial of Ascension</span>
			</div>
		{/if}

		<div class="mt-0 lg:mt-20 absolute left:0 sm:right-8 2xl:mr-10 z-10">
			<Haiku/>
		</div>

		<!-- Background layers: Normal, Success, Fail -->
		{#if !$isGoldLoaded || !_ascensionSuccess}
			<HazeLayer/>
		{:else if ($isGold )}
			<SuccessLayer/>
		{:else if ( !$isGold )}
			<FailureLayer/>
		{/if}

		<div class="flex flex-col-reverse lg:flex-row lg:px-8 mt-4">

			{#if !ascendedView}
				<div class="lg:w-1/2 lg:ml-0 -mt-2 lg:mt-6" in:fade={{delay: 100, duration: 800}} out:fade={{delay: 100, duration: 800}}>
					<img src={Torii} alt="Trial of Ascension" class="w-full  scale-125 relative z-20 mt-12"/>
				</div>
			{/if}

			<div class="{ascendedView ? 'mx-auto max-w-screen-sm xl:max-w-screen-md' : 'lg:w-1/2'} mt-8 lg:mt-0 z-20" in:fade={{delay: 100, duration: 800}} out:fade={{delay: 100, duration: 800}}>

				<!-- Default page -->
				{#if (_ascending === false && _selecting === false)}

					<div class="text-left lg:ml-12 lg:mr-16 lg:mt-14" in:fade>
						<h1 class="main-font text-sm xl:text-xl font-bold mb-0">INITIATE THE TRIAL OF</h1>
						<h1 class="main-font text-4xl xl:text-7xl font-bold mb-4 lg:mb-10">ASCENSION</h1>
						<p class="mb-4 lg:mb-8 small-font">
							The Trial pits your Ronin in combat against its Oni, a final desperate attempt to defeat its eternal tormentor and escape damnation.
						</p>
						<h3 class="text-sm lg:text-sm lg:text-base main-font font-bold text-green-400 lg:mb-2">
							IF YOUR RONIN TRIUMPHS
						</h3>
						<p class="mb-4 lg:mb-8 small-font">
							Its liberated soul will join Amaterasu, the goddess of the sun, in the heavenly realm of Takamagahara. Your Ronin will transcend its original form to become one of only 88 Golden Ronin, a mythic title achievable for just 1% of all Oni Ronin.
						</p>
						<h3 class="text-sm lg:text-base main-font font-bold text-red-500 lg:mb-2">
							IF YOUR RONIN FAILS
						</h3>
						<p class="mb-2 lg:mb-4 small-font">it will plunge into disgrace as a Fallen Ronin, its shame displayed in one of ten soul shades. Should you collect all ten soul shades of Fallen Ronin, you can exchange them for a personalized, one-of-a-kind Ronin created just for you by the Oni Ronin artist.
						</p>
						<div class="float-right">
							<button class="gold-background px-4 lg:px-14 py-2 mt-2 lg:mt-8 secondary-font text-black rounded-full text-xs sm:text-base float-right" on:click={prepForAscension}>CHOOSE YOUR NFTS</button>
						</div>
					</div>


				<!-- Loading Ronin -->
				{:else if (_ascending === true && _selected === true && _selecting === true)}
					Waiting on Your Ronin to Load...
					<img src={asc_bg} alt={"Trial of Ascension"} class="mint_block"/>


				<!-- Confirming ascension -->
				{:else if (_ascending === true && _selected === true && _selecting === false)}

 					<div class="mx-1 -mt-8 lg:mt-0 -mb-40 mx-6 md:mx-6 lg:ml-0 lg:mr-8 xl:ml-16 xl:mx-24 2xl:mr-32">
						<div class=" lg:px-12 text-center relative w-full background bg-cover"  style="background-image: url('{AscensionBlockPlain}');background-size: 100% 100%">

							<div class="z-1 w-full px-[10%] py-[8%] sm-px-20 lg:px-[4%] lg:py-[12%] xl:px-[2%] xl:py-[12%] relative">

								<img src={profile.url} alt={profile.name} class="mx-auto w-full relative z-10 mb-2 bg-black bg-opacity-50"/>
								<!-- For Testing uncomment below and comment above -->
								<!-- <img src={RoninLarge} alt={profile.name} class="mx-auto w-full relative z-10 mb-2 bg-black bg-opacity-50"/> -->

								<h1 class="block ascension_id mb-0 text-xs md:text-sm text-gray-400">#{token}</h1>

								<div class="relative w-full background bg-cover " style="background-image: url('{TicketBox}');background-size: 100% 100%">
									<div class="relative py-[6%] sm:py-[8%] lg:py-[6%] xl:py-[6%] 2xl:py-[6%] z-40 text">
										{#if _loading === true}

											<div class="text-center py-[6%]">
												<p class="">Ascending...</p>
												<p class="">Please do not refresh the page.</p>
											</div>

										{:else if _loading === false}

											<div class="text-center asc_prompt">
												<div class="text-xs md:text-base px-[12%] 2xl:px-[18%] mx-auto mt-0">
													Are you sure you want to Ascend Ronin #{ID}?
												</div>
												<p class="text-xs text-gray-400 mt-2 mb-1">You can not undo this action</p>
											</div>
											<div class="hidden sm:block">
												<div class="mt-2 md:mt-4">
													<button class="bg-[#5f7b5b] text-sm px-4 py-1 rounded-full mr-4" on:click={beginAscension}>YES, ASCEND!</button>
													<button class="bg-[#ba5145] text-sm px-4 py-1 rounded-full" on:click={cancel}>NO, NOT YET.</button>
												</div>
											</div>

										{/if}
									</div>
								</div>
							</div>
						</div>

						<div class="block sm:hidden">
							<div class="mt-6">
								<button class="bg-[#5f7b5b] text-sm px-4 py-1 rounded-full mr-4" on:click={beginAscension}>YES, ASCEND!</button>
								<button class="bg-[#ba5145] text-sm px-4 py-1 rounded-full" on:click={cancel}>NO, NOT YET.</button>
							</div>
						</div>
					</div>

				<!-- Ascension and sucessful or not -->
				{:else if _ascensionSuccess === true && _ascended === true}
					<div class="mx-1 -mt-8 lg:mt-0 -mb-40 mx-6 md:mx-6 lg:mx-8 xl:mx-36">
						<div class=" lg:px-12 text-center relative w-full background bg-cover"  style="background-image: url('{$isGold ? AscensionBlockGlow : AscensionBlockFire}');background-size: 100% 100%">

							<div class="z-1 w-full px-[10%] py-[10%] sm-px-20 lg:px-[4%] lg:py-[12%] xl:px-[2%] xl:py-[12%] relative">

								<div class="max-w-full justify-center flex items-center">
									{#await updateImage}
										<Spinner/>
									{:then}
										{#if updated === true && ascendedURL !== ""}
											<img src={ascendedURL} alt={profile.name} class="mx-auto w-full relative z-10 mb-2 g-black bg-opacity-50"/>
											<!-- For Testing uncomment below and comment above -->
											<!-- <img src={RoninLarge} alt={profile.name} class="mx-auto w-full relative z-10 mb-2 bg-black bg-opacity-50"/> -->

										{:else if updated !== true}
											<p>Loading...</p>
										{/if}
									{/await}
								</div>

								<h1 class="block ascension_id mb-0 text-sm py-1 text-gray-400">#{token}</h1>

								<div class="relative w-full background bg-cover" style="background-image: url('{TicketBox}');background-size: 100% 100%">
									<div class="relative py-[8%] sm:py-[10%] lg:py-[10%] z-40 text-sm md:text-base">

										{#if ($isGoldLoaded && (_ascensionSuccess && $isGold ))}

											<p class="w-4/5 mx-auto">
												Your Ronin was successful and defeated its Oni and has become a <span class="gold-color font-bold">Golden Ronin!</span>
											</p>

										{:else if $isGoldLoaded &&  (_ascensionSuccess && !$isGold )}

											<p class="w-4/5 mx-auto mb-3">
												Your Ronin was defeated by its Oni and has become a <span class="gold-color font-bold">Fallen Ronin</span>!
											</p>

										{/if}
									</div>
								</div>
							</div>
						</div>

						<div class="block">
							<div class=" mt-4">
								<button class="gold-background px-14 py-2 mt-2 lg:mt-6 secondary-font text-black rounded-full text-xs sm:text-base cursor-pointer" on:click={forwardToProfile}>VIEW YOUR NFTS</button>
							</div>
						</div>
					</div>

				{/if}
			</div>
		</div>
	</div>
</div>
<!-- <div class="relative w-full z-10">
	<img src={FogBottom} class="mblock h-96 sm:h-1/6 w-full opacity-50 fog-overlay"/>
</div> -->

<style>
	@media screen and (max-width: 500px) {
		.small-font {
			font-size: smaller;
		}
	}

	.conf_text {
		font-size: 12px;
		text-align: center;
	}
</style>
