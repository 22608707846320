<script>
	import {onMount} from 'svelte';
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import {data as data_alchemy} from '../../stores/web3/alchemy/contracts'
	import HazeLayer from '../shared/HazeLayer.svelte';
	import Haiku from '../../../public/assets/images/bghaiku.svelte'
	import { KitsuneLarge, KitsuneMobile } from '../../../public/assets/urls'
	import { navigateTo } from 'svelte-router-spa'

	let Kitsune;

	onMount(async () => {
		if (w > 500) {
			Kitsune = KitsuneLarge
		} else if (w < 500) {
			Kitsune = KitsuneMobile
		}
	});

	var keys = []
	let w;
	let screenHeight;

	$: unclaimed = Number($data.roninUnclaimed.value)

	const initiateClaim = () => {
		claimRonin(unclaimed)
	}

	const sendEmail = () => {
		console.log('email hit')
	}

	$: {
		keys = Object.keys($data_alchemy)
	}
</script>
<svelte:window bind:innerWidth={w} bind:innerHeight={screenHeight}/>


<div id="main_section" class="container mx-auto max-w-screen-2xl">
	<div class="relative">
		<div class="mt-0 xl:mt-16 absolute right-0 -mr-12 xl:mr-[15%] top-4 w-72">
			<Haiku/>
		</div>

		<div class="px-0 xl:px-12 mt-16 relative">
			<div class="flex flex-col-reverse xl:flex-row">

				<div class="mt-6 xl:-mt-4 xl:-mr-10 flex justify-center xl:w-1/2">
					<img src={Kitsune} class=" xl:max-w-5xl ml-20 xl:ml-0 scale-125 md:scale-100"/>
				</div>

				<div class="z-10 flex flex-col justify-center mx-8 mt-18 xl:ml-16 xl:w-1/2">
					<div class=" mx-auto text-left text-white xl:ml-10">
						<h1 class="main-font text-4xl xl:text-7xl font-bold mb-2">KITSUNE</h1>
						<h2 class="main-font text-sm xl:text-xl font-bold mb-10">YOUR RONIN'S LOYAL SIDEKICK</h2>

						<p class="secondary-font mb-6">
							Though Oni Ronin serve no lord, they are not alone. Every Oni Ronin has a Kitsune companion, a fox spirit warped in its form like the Oni Ronin themselves.
						</p>
						<p class="secondary-font mb-6">
							Kitsune act as guides for the Ronin in the Oni-realm; they both watch and show the way.
						</p>
						<p class="secondary-font mb-8">
							Each Oni Ronin has a separate Kitsune companion NFT <span class="font-bold gold-color">that can be claimed for free, exclusively by Oni Ronin holders.</span>
						</p>

						<button class="gold-background px-14 py-3 mt-2 lg:mt-6 secondary-font text-black rounded-full text-xs sm:text-base float-right relative z-100 sm:leading-none" on:click={ () => { navigateTo('kitsune') } }>
							CLAIM KITSUNE
						</button>

						<!-- <p class="mb-2">
							Stay up to date on the latest Oni Ronin
						</p>
						<p>
							<input type="text" placeholder="EMAIL ADDRESS" class="border-b border-white w-72 bg-transparent py-2" />
						</p>

						<button class="mt-6 float-left border border-amber-200 px-16 py-2 width-24 rounded-full">
							NOTIFY ME
						</button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

