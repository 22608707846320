<script>
	import {onMount} from 'svelte'
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import {data as data_alchemy} from '../../stores/web3/alchemy/contracts'
	import Haiku from '../../../public/assets/images/bghaiku.svelte'
	import {navigateTo} from 'svelte-router-spa'
	import { RoninLarge, RoninMobile } from '../../../public/assets/urls';

	var keys = []
	let w;
	let mobile;
	let Ronin;

	$: unclaimed = Number($data.roninUnclaimed.value);

	const initiateClaim = () => {
		claimRonin(unclaimed)
	}

	$: {
		keys = Object.keys($data_alchemy)
	}

	$: w < 600 ? mobile = true : mobile = false

	onMount(async () => {
		if (w > 500) {
			Ronin = RoninLarge
		} else if (w < 500) {
			Ronin = RoninMobile
		}
	});
</script>

<svelte:window bind:innerWidth={w} />

<div id="main_section" class="container mx-auto max-w-screen-2xl relative">
	<div class="relative w-full">
		<div class="mt-8 lg:mt-32 ml-24 absolute w-72">
			<Haiku/>
		</div>

		<div class="block text-center info-headline mt-10 mx-8">
			<h1 class="collection_info">Oni Ronin are a collection of <span class="section_highlight">8,888</span> demonic samurai living in eternal dishonor on the Ethereum Blockchain.</h1>
		</div>

		<div class="px-0 lg:px-12 mt-16">
			<div class="grid grid-cols-1 lg:grid-cols-2">

				<div class="z-10 flex flex-col justify-center mx-8">
					<div class=" mx-auto text-left text-white lg:ml-16">
						<h1 class="main-font text-4xl lg:text-6xl font-bold mb-3">RONIN</h1>
						<h2 class="main-font text-sm lg:text-xl font-bold mb-6 ">WERE MASTERLESS SAMURAI</h2>
						<p class="section_content mt-10">They served no lord, and so had no honor.</p>
						<p class="section_content">When Ronin died, they met demonic spirits, Oni, on the path to the afterlife. They would battle, and if the Oni won, they consumed the Ronin’s soul and condemned them to eternal damnation, punishment for their dishonorable conduct in life.
						</p>

						<button class="gold-background px-14 py-3 mt-2 lg:mt-6 secondary-font text-black rounded-full text-xs sm:text-base float-right relative z-100 sm:leading-none" on:click={() => {navigateTo('mint')}}>
							MINT NOW
						</button>
					</div>
				</div>

				<div class="mt-6 lg:-mt-4 lg:-ml-10 flex justify-center">
					<img src={Ronin} alt="Mint Your Ronin Now!" class="max-w-full lg:max-w-4xl"/>
				</div>
			</div>
		</div>
	</div>
</div>
