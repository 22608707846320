<script>
	import {onMount} from 'svelte'
	import {state, data, useAlchemy, claimRonin} from '../../stores/manager';
	import Haiku from '../../../public/assets/images/HaikuGold.svelte';
	import CloudLayer from '../shared/CloudLayer.svelte';
	import HazeLayer from '../shared/HazeLayer.svelte';
	import KamonNavigation from '../KamonNavigation.svelte';
	import {fade, fly} from 'svelte/transition';
	import {tick} from 'svelte';
	import {RiceFields as RiceFieldsVideo, RiceFieldsStill} from '../../../public/assets/urls.js'

	let RiceField;

	onMount(async () => {
		if (w > 500) {
			tick()
			RiceField = RiceFieldsVideo
		} else if (w < 500) {
			RiceField = RiceFieldsStill
		}
	});

	let w;
	let y;
	let screenHeight;
	let conceal;
	let mobile;

	$: unclaimed = Number($data.myRoninReserveCount.value);

	const initiateClaim = () => {
		claimRonin(unclaimed)
	};

	$: w < 500 ? mobile = true : mobile = false;

	$: {
		if (w < 500) {
			conceal = false;
		} else if ( y > 925 && w > 600 ) {
			conceal = true;
		} else if (y < 920 && w > 600) {
			conceal = false;
		} else {
			conceal = false;
		}
	}

</script>

<svelte:window bind:scrollY={y} bind:innerWidth={w} bind:innerHeight={screenHeight} />

<!-- ORIGINAL SECTION -->

<div id="main_section" class="{conceal === true ? 'section_1_container_hidden':'section_1_container'}">
	<div class="{conceal === true ? '':'fog_container'}" in:fade={{duration:100 }}>
		<!-- {#if w > 500}
			<CloudLayer/>
		{:else}
			<HazeLayer/>
		{/if} -->
		<HazeLayer/>

		<div class="{conceal === true ? 'hidden':''}">
			{#if y < 800}
				<video playsinline autoplay muted loop  id="bgvid" class="bgvideo" in:fade out:fade={{duration: 100}}>
					<source src={RiceField} type="video/mp4">
				</video>
			{/if}

			<div in:fade={{ duration: 100, delay: 0 }} out:fade={{duration: 200}} class="relative">
				<div class="absolute top-0 left-0 grid grid-cols cols-1 justify-items-center w-full">
					<div class="w-32 md:w-48 z-10 grid justify-items-center mt-20 md:mt-16 mb-20 md:mb-12">
						<Haiku/>
					</div>

					<KamonNavigation/>

					<div in:fade={{delay:0}} out:fade={{duration: 200}} class="mt-0">
						<div class="claim_alert hover:animate-none">
							{#if useAlchemy !== true}
								{#if unclaimed !== 0}
									{#if y < 1000}
										<div class="{w < 500 ? 'mt-16':'mt-4'}" in:fade={{ delay: 100, duration: 100 }} out:fade={{delay: 100, duration: 100}}>
											<button class="base-btn-claim" on:click={initiateClaim}>	<p class="">You have <span class="section_highlight">{unclaimed}</span> unclaimed Ronin.</p></button>
										</div>
									{/if}
								{/if}
							{/if}
						</div>
					</div>

				</div>

				<div class="absolute top-72 left-0 flex justify-center w-full">
					<h1 class="main-font z-40 text-4xl md:text-5xl font-bold">ONI RONIN</h1>
				</div>

				<div class="absolute bottom-12 w-full">
					<CloudLayer/>
				</div>
			</div>
		</div>
	</div>
</div>


<style>
	/* .opening_logo_container {
		opacity: var(--dynamic-opacity)
	} */
</style>
